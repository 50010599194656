import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { SharedService } from '../services/shared.service';
import { SimpleGlobal } from 'ng2-simple-global';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../config/config';

import { CookieService } from 'ngx-cookie-service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './templates/app.component.html',
  styleUrls: ['./styles/app.component.scss']
})
export class AppComponent implements OnInit {
  email;
  title;
  globalVar;
  config = Config;
  constructor(public loginService: LoginService,
              private sharedService: SharedService,
              private sg: SimpleGlobal,
              private translate: TranslateService,
              private cookieService: CookieService,
              public router: Router
  ) {
    this.sg['global'] = this.globalVar = { id: '1'};

    sharedService.changeEmitted$.subscribe(
        text => {
          Object.assign(this.globalVar, text);
          this.sg['global'] = this.globalVar;
        });
      translate.setDefaultLang('pl');
      translate.use('pl');

      if (this.cookieService.get(this.config.global.appName + '_filter')) {
          this.sg['global'].filter = JSON.parse(this.cookieService.get(this.config.global.appName + '_filter'));
      } else {
          this.sg['global'].filter = {location : []};
      }
      if (this.loginService.restrictedTo) {
          this.sg['global'].filter.location = this.loginService.restrictedTo;
          this.cookieService.delete(this.config.global.appName + '_filter');
          this.cookieService.set(this.config.global.appName + '_filter', JSON.stringify(this.sg['global'].filter));
      }
  }
  ngOnInit(): void {
    // this.title = 'Brewety' + (this.globalVar.module ? this.globalVar.module : '') + ( (this.loginService.userId ?  (' - ' + this.loginService.userEmail + ' role - ' + this.loginService.userRole) : ''));
  }
  logout(): void {
    this.loginService.logout();
    window.location.replace('/login');
  }
}
