import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

@Injectable() export class JsonService {
    constructor(private http: HttpClient) { }
    private headers = new HttpHeaders().set('Content-Type', 'application/json');
    private config = Config;
    getJson(json) {
        const url = `/assets/data/` + json;
        return this.http.get(url)
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }
    getRemoteJson(url, strict = true) {
        return this.http.get(url)
            .toPromise()
            .then(response => response)
            .catch(strict ?  this.handleError : this.ignoreError);
    }
    postFile(data, fname) {
        const url = `https://localhost:4434/api/file`;
        return this.http.post(url, JSON.stringify({'data': JSON.stringify(data) , 'fname': fname}) , {headers: this.headers})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);

    }
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
    private ignoreError(error: any): Promise<any> {
        console.warn('An error occurred', error); // for demo purposes only
        return Promise.resolve(error.message || error);
    }

}
