import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {animate, AnimationBuilder, state, style, transition, trigger} from '@angular/animations';
import {FillAnimationService} from "../../services/fill-animation.service";
import {elementFadeState} from "../../libs/animations";

@Component({
    selector: 'app-beer-pour-anim-component',
    templateUrl: '../templates/widgets/beer-fill-animation-component.html',
    styleUrls: ['../styles/widgets/beer-fill-animation-component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [elementFadeState]
})

export class RiotBeerFillAnimationComponent implements OnInit {

    @ViewChild('fill') fill: ElementRef;
    @Input() data;

    private animTime: number = 10000;

    private factory;
    private player;

    constructor(
        private builder: AnimationBuilder,
        private pourAnimService: FillAnimationService
    ) {}

    ngOnInit(): void {

        this.pourAnimService.getAnimState().subscribe((state) => {
            if (state) {
                const animState = state.filter((el) => el.deviceId == this.data.port.DEVICEID && el.portId == this.data.port.PARAM_NUM)[0];
                if (animState) {
                    if (animState.time !== this.animTime) {
                        console.log("animTime: " + animState.time);
                        this.animTime = animState.time;
                        this.factory = this.initFactory(this.animTime);
                        this.animate();
                    }

                    switch (animState.state) {
                        case 'play':
                            this.data.port.working = 1;
                            this.player.play();
                            break;
                        case 'pause':
                            this.data.port.working = 2;
                            this.player.pause();
                            break;
                        case 'reset':
                            this.data.port.working = 0;
                            this.player.reset();
                            break;
                    }
                }
            }
        })
    }

    initFactory(flowtime) {
        return this.builder.build([
            style({'transform': 'translateY(100%)'}),
            animate(flowtime, style({
                'transform': 'translateY(5px)',
                'animation-iteration-count': 1,
                'animation-timing-function': 'cubic-bezier(.2, .6, .8, .4)',
                'animation-fill-mode': 'forwards',
                'animation-delay': '0.25s'
            }))
        ]);
    }

    private animate() {
        this.player = this.factory.create(this.fill.nativeElement, {});

        this.player.onDone(() => {
            this.player.pause();
        });
    }
}
