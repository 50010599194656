import {Injectable} from '@angular/core';
import {Subject, timer} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Config} from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class WeatherService {

    private config = Config;

    constructor(
        private http: HttpClient
    ) {
    }

    getWeatherState(city: string): Subject<string> {
        const dataSubject = new Subject<string>();
        this.http.get(
            this.config.weather.apiUrl+ `?q=${city}&APPID=${this.config.weather.apiKey}`)
            .subscribe((data) => {
                dataSubject.next(data['weather'][0].main);
            });
        return dataSubject;
    }

    getCurrentTemp(city: string, metric: 'metric' | 'imperial' = 'metric'): Subject<number> {
        const dataSubject = new Subject<number>();
        this.http.get(
            this.config.weather.apiUrl + `?q=${city}&units=${metric}&APPID=${this.config.weather.apiKey}`)
            .subscribe((weather: any) => {
                dataSubject.next(Math.round(Number(weather.main.temp)));
            });
        return dataSubject;
    }

}
