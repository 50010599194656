import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

interface stateInterface {
    deviceId,
    portId,
    time,
    state
}

@Injectable({
    providedIn: 'root'
})

export class FillAnimationService {

    constructor() {
    }

    private state = new BehaviorSubject(null);

    getAnimState(): BehaviorSubject<[stateInterface]> {
        return this.state;
    }

    setAnimState(deviceId: number, portId: number, time: number, state: string) {
        this.state.next([
            {
                deviceId: deviceId,
                portId: portId,
                time: Math.round(time),
                state: state
            }
        ]);
    }
}
