import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SimpleGlobal} from 'ng2-simple-global';
import {LoginService} from '../services/login.service';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-login',
    templateUrl: './templates/login.html',
    styleUrls: ['./styles/login.scss']
})


export class LoginComponent implements OnInit {

    imgAnimationOut: boolean = false;
    imgAnimationIn: boolean = false;
    profilePhoto: string = '/assets/unknown-person.gif';
    email = null;
    password = null;
    retypePass = null;
    // registerPassword = {new: null, retype: null};
    // user = new User;
    datePickerConfig: any = {
        mode: 'day',
        locale: 'pl',
        format: 'YYYY-MM-DD',
        firstDayOfWeek: 'mo'
    };

    constructor(public loginService: LoginService,
                // private userService: UserService,
                private sg: SimpleGlobal,
                private router: Router,
                public snackBar: MatSnackBar,
                private translate: TranslateService) {
        this.sg['global'].module = 'Logowanie';
    }

    ngOnInit(): void {
        if (this.loginService.userId) {

        }
    }

    login(): void {
        this.loginService.login(this.email, this.password).then(() => {
            if (this.loginService.userId) {
                this.imgAnimationOut = true;
                this.delay(1000).then(() => {
                    this.router.navigate(['/client']);
                });
            } else {
                this.snackBar.open('brak użytkownika w systemie lub nieprawidłowe hasło', null, {
                    duration: 5000,
                });
                this.password = null;
            }
        });
    }

    delay(time): Promise<void> {
        return new Promise(resolve => {
            setTimeout(resolve.bind(null), time)
        });
    }


    // register(): void {
    //     if (this.loginService.userId) {
    //         this.userService.update(this.user).then(() => {
    //             this.snackBar.open('Twoje dane są zaktualizowane', null, {
    //                 duration: 5000,
    //             });
    //             this.router.navigate(['/login']);
    //             this.retypePass = this.user.password = null;
    //         });
    //     } else {
    //     this.user.status = 1;
    //     this.user.role = 0;
    //     this.userService.create(this.user).then(res => {
    //         // TODO why I can't define different object on output than on input?
    //         if (!res['error']) {
    //             this.loginService.login(this.user.email, this.user.password).then(() => {
    //                 this.translate.get('login.registerSuccesSnack').subscribe((rs: string) => {
    //                     this.snackBar.open(rs, null, {
    //                         duration: 5000,
    //                     });
    //                 });
    //                 this.router.navigate(['/']);
    //                 this.password = this.user.password = this.retypePass = null;
    //             });
    //         } else {
    //             if (res['error'].errno === 19) {
    //                 this.translate.get('login.accountExistsSnack').subscribe((rs: string) => {
    //                     this.snackBar.open(rs, null, {
    //                         duration: 5000,
    //                     });
    //                 });
    //                 this.password = this.user.password = this.retypePass = null;
    //             }
    //         }
    //     });
    //     }
    // }
    // reset(): void {
    //     this.loginService.reset(this.email).then(() => {
    //         this.translate.get('login.resetSnack').subscribe((res: string) => {
    //             this.snackBar.open(res, null, {
    //                 duration: 5000,
    //             });
    //         });
    //         this.email = null;
    //     });
    // }
}
