import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RiotService} from '../../services/riot.service';
import {LoginService} from '../../services/login.service';
import {Config} from '../../config/config';
import _ from "lodash";

import {Observable, Observer, Subscription} from 'rxjs';
import {IMqttMessage, MqttService} from 'ngx-mqtt';
import 'rxjs/add/operator/share';
import * as moment from 'moment';
import {MatDialog} from '@angular/material';
import {FunctionService} from '../../services/shared.service';
import {FillAnimationService} from "../../services/fill-animation.service";

import {elementFadeState, portionState} from "../../libs/animations";

@Component({
    selector: 'app-beer-card-component',
    templateUrl: '../templates/widgets/beer-card-component.html',
    styleUrls: ['../styles/widgets/beer-card-component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [elementFadeState, portionState]
})

export class RiotBeerCardComponent implements OnInit, OnChanges {
    constructor(
        private route: ActivatedRoute,
        private riotService: RiotService,
        private loginService: LoginService,
        private mqttService: MqttService,
        public functionService: FunctionService,
        private fillAnimationService: FillAnimationService
    ) {
        this.observable = new Observable<boolean>((observer: any) => this.observer = observer).share();
    }

    @ViewChild('card') card;
    @ViewChild('fillAnimation') fillAnimation;
    @Output() isFilling = new EventEmitter<boolean>();

    private isToggled: boolean = false;

    public beerFrontText: string;
    public beerBackText: string;

    private subscription: Subscription;
    config = Config;
    @ViewChild('fill') fill: ElementRef;
    @Input() data;
    state = 0;
    frame;
    flowTime;
    flowTimeout;
    stats = {
        curPortion: 0,
        realPortion: 0,
        daily: 0,
        avgDaily: 0,
        total: 0,
        kgFlow: 0,
        kgFlowP: 0,
        kgLeft: 0
    };

    deviceId;
    portId;
    interval;

    public beerRumorMarked: boolean = false;

    public observable: Observable<boolean>;
    private observer: Observer<boolean>;

    ngOnInit(): void {
        this.refreshData();
        this.connectMqtt();
        this.getFakeFrontData();
        this.getFakeBackData();
    }

    connectMqtt() {
        // TODO - check effeciency of that
        // try {
        //     this.mqttService.connect({});
        // } catch (e) {
        //     console.log(e);
        // }
        this.subscription = this.mqttService.observe('beer/data').subscribe((message: IMqttMessage) => {
            const msg = JSON.parse(message.payload.toString());
            for (const row of msg.data) {
                if (row[4].toString() === this.deviceId.toString() && row[0].toString() === this.portId.toString()) {
                    this.data.port.working = 2;
                    this.changeAnimState('pause');
                    this.extendFlow(10000);
                    this.upgradeStats(row);
                }
            }
        });

        this.subscription = this.mqttService.observe('beer/event').subscribe((message: IMqttMessage) => {
            const msg = JSON.parse(message.payload.toString());

            if (msg.event !== 'flow') {
                return;
            }
            if (msg.event_message.device.toString() === this.deviceId.toString()
                && msg.event_message.port.toString() === this.portId.toString()) {
                this.data.port.working = 1;
                this.changeAnimState('play');
                this.extendFlow(10000);
                this.upgradeStats([-1, moment().format('X')]);
            }
        });
    }

    refreshData() {
        this.flowTime = isNaN(this.data.port.maxPortion / (this.data.port.lastVal / this.data.port.lastDur)) ? 10000 :
            (this.data.port.maxPortion / (this.data.port.lastVal / this.data.port.lastDur)) * 1000;
        let flowP = ((this.data.port.sumValB / 1000) / this.data.port.CAPACITY) * 100;
        if (flowP < 0 || (this.data.port.sumValB / 1000) >= this.data.port.CAPACITY) {
            flowP = 0;
        } else if (flowP > 100) {
            flowP = 100;
        } else {
            flowP = 100 - flowP;
        }

        let left = this.data.port.CAPACITY - (this.data.port.sumValB / 1000);
        if (left < 0) {
            left = 0;
        }
        this.stats = {
            curPortion: 0,
            realPortion: 0,
            daily: this.data.port.sumValD,
            avgDaily: this.data.port.avgDVal,
            total: this.data.port.sumValA,
            kgFlow: this.data.port.sumValB,
            kgFlowP: flowP,
            kgLeft: left
        };
        this.deviceId = this.data.port.DEVICEID;
        this.portId = this.data.port.PARAM_NUM;
    }

    ngOnChanges(changes: SimpleChanges) {
        const working: SimpleChange = changes.working;
        if (working && !working.firstChange) {
            if (working.previousValue === 0 || working.previousValue === 2) {
                this.changeAnimState('play');
                this.data.port.working = working.currentValue;
                this.isFilling.emit(true);
                clearTimeout(this.flowTimeout);
            } else {
                this.changeAnimState('pause');
                this.extendFlow(10000);
            }
        }

        const frame: SimpleChange = changes.frame;
        if (frame && !frame.firstChange) {
            if (!frame.previousValue || frame.currentValue[1] !== frame.previousValue[1]) {
                this.upgradeStats(frame.currentValue);
            }
        }
    }

    upgradeStats(frame) {
        if (frame[0] === -1) {
            // TODO for ping
            if (!this.interval) {
                this.stats.curPortion += (this.data.port.lastVal / this.data.port.lastDur) * 0.8;
                this.interval = setInterval(() => {
                    this.stats.curPortion += (this.data.port.lastVal / this.data.port.lastDur) * 0.8;
                }, 1000);
            }
        } else {
            clearInterval(this.interval);
            this.interval = null;
            this.stats.realPortion += (frame[2] * this.data.port.Q);
            this.stats.daily += (frame[2] * this.data.port.Q);
            this.stats.total += (frame[2] * this.data.port.Q);
            this.stats.curPortion = this.stats.realPortion;
            this.observer.next(true);
        }
    }

    toggleState() {
        if (!this.loginService.canUpdate()) {
            return;
        }
        if (this.data.port.working === 0 || this.data.port.working === 2) {
            this.isFilling.emit(true);
            this.changeAnimState('play');
            clearTimeout(this.flowTimeout);
        } else {
            this.extendFlow(10000);
            this.changeAnimState('pause');
        }
    }

    animationReset() {
        this.changeAnimState('reset');
    }

    getDevice() {
        this.riotService.getDevice(this.deviceId).then(json => {
            const dev = json.result[0]; // should be always 1
            this.data.dev = dev;
            const k = dev.ports.find(item => {
                return item.PARAM_NUM.toString() === this.portId.toString();
            });
            if (k) {
                let oldLogo = this.data.port.LOGO; // temp
                this.data.port = k;
                this.data.port.working = 0;
                this.data.port.LOGO = oldLogo; // temp
            }
            this.refreshData();
        });
    }

    closeFlow() {
        this.data.port.working = 0;
        this.stats.curPortion = 0;
        this.stats.realPortion = 0;
        this.getDevice();
        clearInterval(this.interval);
        this.animationReset();

    }

    extendFlow(s) {
        clearTimeout(this.flowTimeout);
        // if (this.stats.curPortion <= this.data.port.maxPortion)
        this.flowTimeout = setTimeout(() => {
            this.closeFlow();
        }, s);
    }

    private getFakeFrontData() {
        const beerInfoArr: Array<string> = [
            "<span class='number'>" + this.randomNumber(27, 48) + "%</span> of our clients choose <span class='brand'>" + this.data.port.MARK + "</span>",
            "Today we have already poured <span class='number'>" + this.randomNumber(56, 95) + "</span> full mugs of <span class='brand'>" + this.data.port.MARK + "</span>",
            "Since the beginning we have already poured <span class='number'>" + this.randomNumber(1500, 2000) + "</span> full glasses of this beer.",
            "Our observations show that <span class='brand'>" + this.data.port.MARK + "</span> is most often chosen by men with a moustache.",
            "We can say that <span class='brand'>" + this.data.port.MARK + "</span> is most often chosen by men having a brown leather wallet.",
            "<span class='number'>" + this.randomNumber(75, 90) + "%</span> clients return for another <span class='brand'>" + this.data.port.MARK + "</span>",
            "If you buy <span class='brand'>" + this.data.port.MARK + "</span> - it will be a <span class='number'>" + this.randomNumber(85, 110) + "</span> mug of this beer poured today.",
        ];
        this.beerFrontText = _.sample(beerInfoArr);
    }

    private getFakeBackData() {
        const doYouKnowArr: Array<string> = [
            "The first beer production took place in Babylonia around 6000 B.C. At that time it was considered the best, noble occupation for women.",
            "Beer is the third most popular beverage in the world after water and tea. It is the most common alcohol.",
            "In ancient Egypt it was believed that beer is a beverage that can be used to treat diseases.",
            "According to Manfred Heun of Oslo University of Life Sciences, beer production could have been the main reason why man started growing wheat",
            "Beer was not considered to be alcohol in Russia until 2013.",
            "The main prize in the wives' competition is as many litres of beer as many kilograms the winner's wife weighs.",
            "Slaves building Egyptian pyramids received beer with bread as a payment.",
            "Carlsberg Brewery in 1922, after the Danish physicist Niels Bohr received the Nobel Prize, as a recognition he led the source of beer directly to his house.",
            "Cows whose meat is used to make the world's most expensive beef are given beer to wheeze their appetite",
            "In Mesopotamia, beer served as a currency for a certain period of time.",
            "The oldest known recipe is the beer recipe written on stone plates over 5000 years ago.",
            "According to research, the average time of drinking a half a litre mug is 10 minutes.",
            "Oliver Struempfel from Germany set a Guinness record of transferring 25 full tankards to a total of 40 metres.",
            "Bubbles in beer strengthen its structure, which makes it more difficult to pour them out than e.g. ordinary water",
            "The strongest beer in the world is Mistery of Beer. It has as much as 70%",
            "Vielle Bon Secours is the most expensive beer in the world. A 12 litre bottle costs about 3000 PLN",
            "Until the mid 1930s, the logo of the Danish Carlsberg beer was swastika",
            "Most beer in Europe is consumed in the Czech Republic - 149 litres per person per year",
            "The first Olympic athlete to be disqualified for doping only drank two beers.",
            "Australian scientists invented a beer which, thanks to its eloctrolytes, hydrates the body.",
            "In Africa you can buy banana beer.",
        ];
        this.beerBackText = _.sample(doYouKnowArr);
    }

    public beerRumorRate(state) {
        // TODO: send state out
        this.beerRumorMarked = true;
    }

    private randomNumber(min, max) {
        return Math.ceil(Math.random() * (max - min) + min);
    }

    public flipCard() {
        this.card.toggle();
        this.isToggled = !this.isToggled;
    }

    public flipReset() {
        if (this.isToggled) {
            this.flipCard();
        }
    }

    private changeAnimState(state: string) {
        this.fillAnimationService.setAnimState(this.deviceId, this.portId, this.flowTime, state);
    }
}
