import { Component, OnInit, ApplicationRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SimpleGlobal } from 'ng2-simple-global';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../services/login.service';
import { RiotService } from '../services/riot.service';
import { Config } from '../config/config';
import * as L from 'leaflet';
import * as moment from 'moment';
import { MqttService, IMqttMessage} from 'ngx-mqtt';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription } from 'rxjs';
import { DictionaryService } from '../services/dictionary.service';
import { Dictionary } from '../data/dictionary.data';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';
import {FunctionService} from '../services/shared.service';
import {RiotCropperModalComponent} from './modals/cropper-modal-component';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'app-riot-devices',
    templateUrl: './templates/devices-component.html',
    styleUrls: ['./styles/devices-component.scss'],
    animations: [
        trigger('devState', [
            state('inactive', style({
                backgroundColor: '#cfd8dc',
            })),
            state('active',   style({
                backgroundColor: '#00c800',
            })),
            state('dead',   style({
                backgroundColor: '#b30000',
            })),
            transition('inactive => active', animate('200ms ease-in')),
            transition('active => inactive', animate('200ms ease-out'))
        ])
    ]
})

export class RiotDevicesComponent implements OnInit {
    private subscription: Subscription;
    constructor(
        private route: ActivatedRoute,
        private riotService: RiotService,
        private sg: SimpleGlobal,
        private translate: TranslateService,
        private applicationRef: ApplicationRef,
        private mqttService: MqttService,
        public loginService: LoginService,
        private router: Router,
        private cookieService: CookieService,
        private dictionaryService: DictionaryService,
        public functionService: FunctionService,
        public dialog: MatDialog
        ) {

    }
    @ViewChild('devTable') table: any;
    selected = [];
    expanded: any = {};
    moment = moment;
    config = Config;
    devices = [];
    fullDevices = [];
    options = {};
    map;
    layers;
    location =  this.sg['global'].filter['location'] || [];
    locations: Dictionary[];

    ngOnInit(): void {
        this.getDict();
        if (!this.loginService.userId) {
            this.router.navigate(['/login']);
            return;
        }
        if (this.location.length > 0 ) {
            this.getDeviceData().then(() => {

                this.filter();
                this.addDevicesToMap();
                this.connectMqtt();
            });
        }
        this.initMapData();
    }
    initMapData() {
        this.options = {
            layers: [
                L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '' })
            ], zoom: 10,
            center: L.latLng([ 52.232855, 20.9211113])
        };
    }
    getDict(): void {
        this.dictionaryService.getList('location').then(locations => this.locations = locations );
    }
    onMapReady(map: L.Map) {
        this.map = map;
    }
    addDevicesToMap() {
        if (this.devices.length === 0) {
            return;
        }
        const markers = [];
        for (const row of this.devices) {
                let lat = parseFloat(row.lat);
                let lon = parseFloat(row.lon);
                if (isNaN(lat) || isNaN(lon)) {
                    lat = 52.232855;
                    lon = 20.9211113;
                    continue;
                }
            const marker =  L.marker([lat, lon]).addTo(this.map);
            marker.bindPopup('<b>' + row.NAME + '</b><br>' + row.locationName);
            markers.push(marker);
        }
        const constgroup = new L.featureGroup(markers);
        this.map.fitBounds(constgroup.getBounds().pad(0.5));


    }

    toggleState(k): void {
        k.state = k.state === 'active' ? 'inactive' : 'active';
    }
    openDialogCropper(beerId): void {

        const dialogRef = this.dialog.open(RiotCropperModalComponent, {
            width: '600px',
            height: '550px',
            data: {beerId: beerId},

        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                     this.uploadMedallion(result.beerId, result.image);
            }
        });
    }
    connectMqtt () {
        // try {
        //     this.mqttService.connect({});
        // } catch (e) {
        //     console.log(e);
        // }
        this.subscription = this.mqttService.observe('beer/ping').subscribe((message: IMqttMessage) => {
            const msg = JSON.parse(message.payload.toString());
            const k =  this.devices.find(item => {
                return item.ID === msg.id;
            });

            if (k) {
                // TODO - should refresh whole device
                k.LAST_SEEN = moment().format('YYYY-MM-DD HH:mm:ss');
                k.LAST_IP = msg.network;
                k.LAST_HEAP = msg.heapfree;
                this.toggleState (k);
                setTimeout(() => {
                        this.toggleState (k);
                    },
                    1000);

            } else {
                // this.getDeviceData(); -- this is parked due to filtering
            }



        });
    }
    // TODO wywalić to do funkcji gdzieś

    getDeviceData() {
        return new Promise(resolve => {
            this.riotService.getDevices().then(json => {
                this.devices = this.fullDevices = json.result;
                this.addDevicesToMap();
                for (const row of json.result ) {
                    if (moment().diff(moment(row.LAST_SEEN), 'minutes') > 10) {
                        row.state = 'dead';
                    } else {
                        row.state = 'inactive';
                    }
                }
                resolve(json.result);
            });
        });
    }
    filter() {
        if (this.fullDevices.length === 0 ) {
            this.getDeviceData().then(() => {
            });
        }
        const devTemp = [];
        this.devices = this.fullDevices;
        for (const row of this.devices ) {
            const k =  this.location.find(item => {
                return (row.LOCATION || '').toString() === item.toString();
            });
            if (k) {
                devTemp.push(row);
            }
        }
        this.devices = [...devTemp];
        this.sg['global'].filter['location'] = this.location;
        this.cookieService.set(this.config.global.appName + '_filter', JSON.stringify(this.sg['global'].filter));
    }
    uploadMedallion (beerId, image) {
        this.riotService.uploadMedallion(beerId, image).then(json => {
            this.getDeviceData();
        });
    }
    toggleExpandRow(row) {
        //console.log('Toggled Expand Row!', row);
        this.table.rowDetail.toggleExpandRow(row);
    }
    glueName(ports) {
        const devs = [];
        if (!ports.lenght && ports.lenght < 1) {
            return 'empty';
        }
        for ( const port of ports) {
            if (port.state === 1 ) {
                devs.push(port.MARK);
            }
        }
        return devs.join(',');
    }
}
