import { Component, OnInit, Inject } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { SimpleGlobal } from 'ng2-simple-global';

import { Config } from '../../config/config';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Observable} from 'rxjs/Observable';
@Component({
    selector: 'app-riot-event-nodal',
    templateUrl: '../templates/modals/event-modal-component.html',
    styleUrls: ['../styles/modals/event-modal-component.scss']
})
export class RiotEventModalComponent implements OnInit {
    config = Config;
    EventMessage = '';
    EventHeader = '';
    EventIdentifier = null;
    EventPic = null ;
    constructor(public loginService: LoginService,
                private sg: SimpleGlobal,
                private translate: TranslateService,
                private cookieService: CookieService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<RiotEventModalComponent>,
    ) {
    }
    ngOnInit(): void {
        this.EventIdentifier =  this.data.id;
      if (this.data.type === 'barrel') {
          this.EventHeader = `Barrel change detected`;
          this.EventMessage = `Our sensors detected barrel change, do you confirm?`;
          this.EventPic = `keg.png`;
      }
    }
    onNoClick(): void {
        this.dialogRef.close(false);
    }
    onYesClick(): void {
        this.dialogRef.close(true);
    }
}
