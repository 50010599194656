export const Config = {
    global: {
        appName: 'riot-ui',
        domain: '',
        datePickerConfig: {
            mode: 'day',
            locale: 'pl',
            format: 'YYYY-MM-DD',
            firstDayOfWeek: 'mo'}
    },
    event: {
        markOwnRows: false
    },
    api: {
        // url: 'http://localhost:3000/api'
       url: 'http://vps1.imigne.pl:3000/api',
       imageUrl : 'http://riot-ui.vps1.imigne.pl'
   },
   mqtt: {
       hostname : 'vps1.imigne.pl',
       port: 3001
   },
   weather:{
        apiUrl: 'https://api.openweathermap.org/data/2.5/weather',
        apiKey: 'e40380424a33b776139e105d3895063c',
        city: 'Warszawa'
   }
};
