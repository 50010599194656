import { Component, OnInit, ApplicationRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SimpleGlobal } from 'ng2-simple-global';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../services/login.service';
import { RiotService } from '../services/riot.service';
import { Config } from '../config/config';
import * as moment from 'moment';
import { Dictionary } from '../data/dictionary.data';
import { DictionaryService } from '../services/dictionary.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription } from 'rxjs';
import { FunctionService} from '../services/shared.service';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

@Component({
    selector: 'app-riot-location',
    templateUrl: './templates/location-component.html',
    styleUrls: ['./styles/location-component.scss'],
    animations: [
        trigger('devState', [
            state('inactive', style({
                backgroundColor: '#cfd8dc',
            })),
            state('active',   style({
                backgroundColor: '#00c800',
            })),
            state('dead',   style({
                backgroundColor: '#b30000',
            })),
            transition('inactive => active', animate('200ms ease-in')),
            transition('active => inactive', animate('200ms ease-out'))
        ])
    ]
})

export class RiotLocationComponent implements OnInit {
    private subscription: Subscription;
    constructor(
        private route: ActivatedRoute,
        private riotService: RiotService,
        private sg: SimpleGlobal,
        private translate: TranslateService,
        private applicationRef: ApplicationRef,
        public loginService: LoginService,
        private router: Router,
        private dictionaryService: DictionaryService,
        private cookieService: CookieService,
        private functionService: FunctionService
        ) {
    }
    locations: Dictionary[];
    location = this.sg['global'].filter['location'] || [];
    config = Config;
    devices = [];
    fullDevices = [];
    options = {};
    map;
    pieCharts = {sumA: [], avgD: [], avgA: []};
    layers;
    colorScheme = {
        domain: [ '#ffb287', '#ffe3d4', '#d56217', '#df7e2b', '#e69138', '#eeb274', '#f8dec3']
    };

    ngOnInit(): void {
        this.getDict();
        if (!this.loginService.userId) {
            this.router.navigate(['/login']);
            return;
        }
        if (this.location.length > 0 ) {
            this.getDeviceData().then(() => {
                this.filter();
            });
        }
    }
    getDict(): void {
        this.dictionaryService.getList('location').then(locations => this.locations = locations );
    }
    // TODO wywalić to do funkcji gdzieś

    getDeviceData() {
        return new Promise(resolve => {
            this.riotService.getDevices().then(json => {
                this.devices = this.fullDevices = json.result;
                this.pieCharts = this.functionService.devToChar([...this.devices]);
                for (const row of json.result ) {
                    if (moment().diff(moment(row.LAST_SEEN), 'minutes') > 10) {
                        row.state = 'dead';
                    } else {
                        row.state = 'inactive';
                    }
                    for (const subrow of row.ports) {
                        subrow.working = 0;
                    }
                }
                resolve(json.result);
            });
        });
    }
    filter() {
        const devTemp = [];
        this.devices = this.fullDevices;
        for (const row of this.devices ) {
            const k =  this.location.find(item => {
                return (row.LOCATION || '').toString() === item.toString();
            });
            if (k) {
                devTemp.push(row);
            }
        }
        this.devices = [...devTemp];
        this.pieCharts = {sumA: [], avgD: [], avgA: []};
        this.pieCharts = this.functionService.devToChar([...devTemp]);

        this.sg['global'].filter['location'] = this.location;
        this.cookieService.set(this.config.global.appName + '_filter', JSON.stringify(this.sg['global'].filter));
    }
}
