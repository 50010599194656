import {ApplicationRef, Component, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Config} from '../config/config';
import * as moment from 'moment';
import {SimpleGlobal} from 'ng2-simple-global';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from '../services/login.service';
import {RiotService} from '../services/riot.service';
import {DictionaryService} from '../services/dictionary.service';
import {CookieService} from 'ngx-cookie-service';
import {switchMap} from 'rxjs/operators';
import {Subscription, timer} from 'rxjs';

import {FunctionService} from '../services/shared.service';
import {WeatherService} from "../services/weather.service";

import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';

import {SwiperComponent, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {RiotBeerCardComponent} from "./widgets/beer-card-component";

import {elementFadeEnter, slideInOut} from "../libs/animations";

import * as _ from "lodash";
import {forEach} from "@angular/router/src/utils/collection";

@Component({
    selector: 'app-riot-client',
    templateUrl: './templates/client-component.html',
    styleUrls: ['./styles/client-component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [elementFadeEnter, slideInOut]
})

export class RiotClientComponent implements OnInit, OnDestroy {

    constructor(
        public loginService: LoginService,
        private route: ActivatedRoute,
        private riotService: RiotService,
        private sg: SimpleGlobal,
        private translate: TranslateService,
        private applicationRef: ApplicationRef,
        private router: Router,
        private dictionaryService: DictionaryService,
        private cookieService: CookieService,
        private functionService: FunctionService,
        private weather: WeatherService,
        private idle: Idle
    ) {
    }

    config = Config;
    items = [];
    options = {};
    map;

    readonly locationId: number = 4; //Narnia
    readonly idleTimeout: number = 100;

    public idleState: string = 'Not started.';
    public taxiPhoneNumber: string = '22 999 99 99';

    @ViewChild('swiper') swiper: SwiperComponent;
    @ViewChildren('card') card: QueryList<RiotBeerCardComponent>;


    public currentTime: number = Date.now();
    public currentTemp: number;
    private tempSubscription: Subscription;


    private tempBeerImg = [
        '/assets/temp/global-brew-tap-crraft-brew-bash-logo.jpg',
        '/assets/temp/beer-type-logo-pirataone.jpg',
        '/assets/temp/united-states-food-beer-tourism-logo-design.jpg',
        '/assets/temp/craft-beer-logo-etiqueta-vintage-para-brew-house-ou-pub_7112-356.jpg',
        '/assets/temp/picture1457531019719.jpg',
    ];

    public news = [
        {
            type: "message",
            title: "Beer Fest!",
            text: "Buy one beer - get second one with 30% discount :)"
        },
        {
            type: "message",
            title: "Happy San Miguel Hour!",
            text: "You still have <div class='chip'>00:33</div> minutes to buy ice cold San Miguel for just 3 bucks!"
        },
    ];

    public swiperconfig: SwiperConfigInterface = {
        observer: true,
        a11y: true,
        direction: 'horizontal',
        speed: 200,
        effect: 'slide',
        coverflowEffect: {
            slideShadows: false,
            depth: 0,
            rotate: 30
        },
        init: true,
        loop: false,
        freeMode: true,
        resistance: true,
        resistanceRatio: 0,
        spaceBetween: 15,
        slidesPerView: 3,
        keyboard: true,
        mousewheel: true,
        nested: true,
        autoplay: {
            delay: 5000,
        },
    };

    ngOnInit(): void {
        if (!this.loginService.userId) {
            this.router.navigate(['/login']);
            return;
        }
        this.setScaleToFitViewport();

        this.getDevices();
        this.clockTick();
        this.initIdleDetector();

        this.weather.getWeatherState(this.config.weather.city).subscribe((state) => console.log(state));

        this.tempSubscription = timer(0, 1000 * 60 * 10).pipe(
            switchMap(() => this.weather.getCurrentTemp(this.config.weather.city))
        ).subscribe(temp => this.currentTemp = temp);
    }

    ngOnDestroy() {
        this.tempSubscription.unsubscribe();
    }

    private setScaleToFitViewport(): void{
        let viewPortWidth:number;
        (typeof window.innerWidth != 'undefined')?viewPortWidth = window.innerWidth:viewPortWidth = screen.width;


        if (1600 < viewPortWidth || viewPortWidth < 1024) {
            console.log('scale, screen.width: '+viewPortWidth);
            const siteWidth = 1366;
            let scale = (viewPortWidth / siteWidth);
            document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=' + siteWidth + ', initial-scale=' + scale + ', user-scalable=no');
        }
    }

    private clockTick() {
        setInterval(() => {
            this.currentTime = Date.now();
        }, 1000);
    }

    private initIdleDetector() {
        this.idle.setIdle(1);
        this.idle.setTimeout(0);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => {
            this.idleState = "No longer idle.";
            this.swiper.directiveRef.stopAutoplay();
            this.swiper.directiveRef.update();
        });
        this.idle.onTimeout.subscribe(() => {
            this.idleState = "Timed out!";
            this.idle.watch();
        });
        this.idle.onIdleStart.subscribe(() => {
            this.idle.setIdle(this.idleTimeout);
            this.idleState = "You've gone idle!";

            this.card.forEach((card) => {
                card.flipReset()
            });
            this.swiper.directiveRef.startAutoplay();
            this.swiper.directiveRef.update();
        });
        this.idle.watch();
        this.idleState = "Started.";
    }

    private getDevices() {
        this.riotService.getDevices().then(json => {
            for (const row of json.result) {
                if (moment().diff(moment(row.LAST_SEEN), 'minutes') > 10) {
                    row.state = 'dead';
                } else {
                    row.state = 'inactive';
                }
                for (const subrow of row.ports) {
                    subrow.working = 0;
                    subrow.LOGO = _.sample(this.tempBeerImg);
                }
            }
            let devices = json.result.filter((value) => {
                return value.LOCATION === this.locationId;
            });

            for (let device of devices) {
                for (let port of device.ports) {
                    this.items.push(port);
                }
            }

            this.swiper.directiveRef.startAutoplay();
            this.swiper.directiveRef.update();
        });
    }

    public btnClick() {
        this.swiper.directiveRef.setIndex(this.getRandomArbitrary(0, this.items.length));
    }

    private getRandomArbitrary(min, max) {
        return Math.ceil(Math.random() * (max - min) + min);
    }

    public slideCenter(slideIndex) {
        if (this.idle.isIdling()) this.swiper.directiveRef.setIndex(slideIndex - 1);
    }

}
