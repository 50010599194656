import {animate, state, style, transition, trigger} from "@angular/animations";

export const elementFadeState = trigger('elementFadeState', [
    state('0', style({
        opacity: 0,
    })),
    state('1', style({
        opacity: 1,
    })),
    transition('0 => 1', animate('500ms ease-in')),
    transition('1 => 0', animate('500ms ease-out'))
]);

export const elementFadeEnter = trigger('elementFadeEnter', [
    state('in', style({opacity: 1})),

    transition(':enter', [
        style({opacity: 0}),
        animate(800)
    ]),

    transition(':leave',
        animate(0, style({opacity: 0})))
]);


export const portionState = trigger('portionState', [
    state('0', style({
        opacity: '0.3',
    })),
    state('1', style({
        opacity: '1',
    })),
    transition('0 => 1', animate('500ms ease-in')),
    transition('1 => 0', animate('500ms ease-out'))
]);

export const slideInOut = trigger('slideInOut', [
    transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
    ]),
    transition(':leave', [
        animate('500ms ease-out', style({
            transform: 'translateX(300px)',
            opacity: 0
        }))
    ])
]);
