import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

@Injectable() export class RiotService {
    constructor(private http: HttpClient) { }
    private headers = new HttpHeaders().set('Content-Type', 'application/json');
    private config = Config;

    getRangeRawData(locations, dateFrom, dateTo) {
        const url = this.config.api.url + '/getRangeRawData';
        return this.http.post(url, JSON.stringify({locations: locations, dateFrom: dateFrom, dateTo: dateTo}) , {headers: this.headers})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }
    getRangeDailyData(locations, dateFrom, dateTo) {
        const url = this.config.api.url + '/getRangeDailyData';
        return this.http.post(url, JSON.stringify({locations: locations, dateFrom: dateFrom, dateTo: dateTo}) , {headers: this.headers})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }
    dailyFlow(day) {
        const url = this.config.api.url + '/dailyFlow';
        return this.http.post(url, JSON.stringify({'day': day}) , {headers: this.headers})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);

    }
    dailyFullFlow(day, device) {
        const url = this.config.api.url + '/dailyFullFlow';
        return this.http.post(url, JSON.stringify({'day': day, 'device': device}) , {headers: this.headers})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);

    }
    getDevices() {
        const url = this.config.api.url + '/getDevices';
        return this.http.get(url, {headers: this.headers})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }
    getDevice(device) {
        const url = `${this.config.api.url}/getDevice/${device}`;
        return this.http.get(url, {headers: this.headers})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }
    changeBarrel (device ) {
        const url = this.config.api.url + '/changeBarrel';
        return this.http.post(url, JSON.stringify({'device': device}) , {headers: this.headers})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }
    updateQ (device, q, from ) {
        const url = this.config.api.url + '/updateQ';
        return this.http.post(url, JSON.stringify({'device': device, 'q': q, from: from}) , {headers: this.headers})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }
    updateFlow (device, flow, date ) {
        const url = this.config.api.url + '/updateFlow';
        return this.http.post(url, JSON.stringify({'device': device, 'flow': flow, date: date}) , {headers: this.headers})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }
    uploadMedallion(beerId, image) {
        const url = this.config.api.url + '/uploadMedallion';
        return this.http.post(url, JSON.stringify({beerId: beerId, image: image}) , {headers: this.headers})
            .toPromise()
            .then(response => response)
            .catch(this.handleError);
    }
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
    private ignoreError(error: any): Promise<any> {
        console.warn('An error occurred', error); // for demo purposes only
        return Promise.resolve(error.message || error);
    }

}
