import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import { LoginService } from '../../services/login.service';
import { SimpleGlobal } from 'ng2-simple-global';

import { Config } from '../../config/config';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Observable} from 'rxjs/Observable';
import { CropperSettings } from 'ngx-img-cropper';
@Component({
    selector: 'app-riot-cropper-nodal',
    templateUrl: '../templates/modals/cropper-modal-component.html',
    styleUrls: ['../styles/modals/cropper-modal-component.scss'],
})
export class RiotCropperModalComponent implements OnInit {
    config = Config;
    @ViewChild('cropper', undefined) cropper: any;
    cropperSettings: CropperSettings;
    cropperData: any;
    constructor(public loginService: LoginService,
                private sg: SimpleGlobal,
                private translate: TranslateService,
                private cookieService: CookieService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<RiotCropperModalComponent>,
    ) {
        this.cropperSettings = new CropperSettings();
        this.cropperSettings.width = 150;
        this.cropperSettings.height = 150;
        this.cropperSettings.croppedWidth = 100;
        this.cropperSettings.croppedHeight = 100;
        this.cropperSettings.canvasWidth = 400;
        this.cropperSettings.canvasHeight = 300;
        this.cropperSettings.noFileInput = true;
        this.cropperSettings.keepAspect = true;
        this.cropperSettings.cropperClass = 'medalion';
        this.cropperData = {beerId : this.data.beerId};
        this.cropperSettings.resampleFn = (buffer: HTMLCanvasElement) => {

            const canvasContext = buffer.getContext('2d');
            const imgW = buffer.width;
            const imgH = buffer.height;
            const imgPixels = canvasContext.getImageData(0, 0, imgW, imgH);


            for (let y = 0; y < imgPixels.height; y++) {
                for (let x = 0; x < imgPixels.width; x++) {
                    const i = (y * 4) * imgPixels.width + x * 4;
                    const avg = (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
                    imgPixels.data[i] = avg;
                    imgPixels.data[i + 1] = avg;
                    imgPixels.data[i + 2] = avg;
                }
            }
        };
    }
    fileChangeListener($event) {
        const image: any = new Image();
        const file: File = $event.target.files[0];
        const myReader: FileReader = new FileReader();
        myReader.onloadend =  (loadEvent: any) => {
            image.src = loadEvent.target.result;
            this.cropper.setImage(image);

        };
        myReader.readAsDataURL(file);
    }
    ngOnInit(): void {
    }
    onNoClick(): void {
        this.dialogRef.close(false);
    }
    onYesClick(): void {
        this.dialogRef.close(this.cropperData);
    }
}
