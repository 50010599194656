import { Component, OnInit, Input, ElementRef, ViewChild, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { RiotService } from '../../services/riot.service';
import { LoginService } from '../../services/login.service';
import { Config } from '../../config/config';
import { trigger, state, style, animate, transition, AnimationBuilder, AnimationPlayer } from '@angular/animations';

import { Observable, Observer } from 'rxjs';
import { MqttService, IMqttMessage} from 'ngx-mqtt';
import 'rxjs/add/operator/share';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import {RiotEventModalComponent} from '../modals/event-modal-component';
import {FunctionService} from '../../services/shared.service';

@Component({
    selector: 'app-riot-flow-widget',
    templateUrl: '../templates/widgets/flow-widget-component.html',
    styleUrls: ['../styles/widgets/flow-widget-component.scss'],
    animations: [
        trigger('portionState', [
            state('0', style({
                opacity: '0.3',
            })),
            state('1',   style({
                opacity: '1',
            })),
            transition('0 => 1', animate('500ms ease-in')),
            transition('1 => 0', animate('500ms ease-out'))
        ]),
        trigger('flowState', [
            state('0', style({
                opacity: '0',
            })),
            state('1',   style({
                opacity: '1',
            })),
            transition('0 => 1', animate('500ms ease-in')),
            transition('1 => 0', animate('500ms ease-out'))
        ])
    ]
})

export class RiotFlowWidgetComponent implements OnInit, OnChanges {
    constructor(
        private route: ActivatedRoute,
        private riotService: RiotService,
        private builder: AnimationBuilder,
        private loginService: LoginService,
        private mqttService: MqttService,
        public dialog: MatDialog,
        public functionService: FunctionService
    ) {
        this.observable = new Observable<boolean>((observer: any) => this.observer = observer).share();
    }
    private subscription: Subscription;
    config = Config;
    @ViewChild('fill') fill: ElementRef;
    @Input() data;
    state = 0;
    frame;
    flowTime;
    flowTimeout;
    stats = {
        curPortion: 0,
        realPortion: 0,
        daily: 0,
        avgDaily: 0,
        total: 0,
        kgFlow: 0,
        kgFlowP: 0,
        kgLeft: 0
    };

    deviceId;
    portId;
    interval;
    public factory;
    public player;

    public observable: Observable<boolean>;
    private observer: Observer<boolean>;
    ngOnInit(): void {
        this.refreshData();
        this.animate();
        this.connectMqtt();
    }
    connectMqtt () {
        // TODO - check effeciency of that
        // try {
        //     this.mqttService.connect({});
        // } catch (e) {
        //     console.log(e);
        // }
        this.subscription = this.mqttService.observe('beer/data').subscribe((message: IMqttMessage) => {
            const msg = JSON.parse(message.payload.toString());
            for (const row of msg.data) {
                if (row[4].toString() === this.deviceId.toString() && row[0].toString() === this.portId.toString()) {
                    this.data.port.working = 2;
                    this.player.pause();
                    this.extendFlow(10000);
                    this.upgradeStats(row);
                }
            }
        });

        this.subscription = this.mqttService.observe('beer/event').subscribe((message: IMqttMessage) => {
            const msg = JSON.parse(message.payload.toString());

            if (msg.event !== 'flow') {
                return;
            }
            if (msg.event_message.device.toString() === this.deviceId.toString()
                && msg.event_message.port.toString() === this.portId.toString()) {
                this.data.port.working = 1;
                this.player.play();
                this.extendFlow(10000);
                this.upgradeStats([-1, moment().format('X')]);
            }
        });
    }
    openDialogEvent(event): void {

        const dialogRef = this.dialog.open(RiotEventModalComponent, {
            width: '400px',
            height: '500px',
            data: {id: this.data.port.MARK, type: event},

        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (event === 'barrel') {
                    this.changeBarrel();
                }
            }
        });
    }


    refreshData() {
        this.flowTime = isNaN(this.data.port.maxPortion / (this.data.port.lastVal / this.data.port.lastDur)) ? 10000 :
            (this.data.port.maxPortion / (this.data.port.lastVal / this.data.port.lastDur)) * 1000;
        this.factory =  this.initFactory(this.flowTime);
        let flowP = (( this.data.port.sumValB / 1000) / this.data.port.CAPACITY ) * 100;
        if (flowP < 0 || ( this.data.port.sumValB / 1000) >= this.data.port.CAPACITY ) {
            flowP = 0;
        } else if (flowP > 100 ) {
            flowP = 100;
        } else  { flowP = 100 - flowP; }

        let left = this.data.port.CAPACITY - ( this.data.port.sumValB / 1000);
        if (left < 0) {left = 0; }
        this.stats = {
            curPortion: 0,
            realPortion: 0,
            daily: this.data.port.sumValD,
            avgDaily: this.data.port.avgDVal,
            total: this.data.port.sumValA,
            kgFlow: this.data.port.sumValB,
            kgFlowP: flowP,
            kgLeft: left
        };
        this.deviceId = this.data.port.DEVICEID;
        this.portId = this.data.port.PARAM_NUM;
        // console.log(this.stats);
    }
    ngOnChanges(changes: SimpleChanges) {
        const working: SimpleChange = changes.working;
        if (working && !working.firstChange) {
            if (working.previousValue === 0 || working.previousValue === 2) {
                this.player.play();
                this.data.port.working = working.currentValue;
                clearTimeout(this.flowTimeout);
            } else {
                this.player.pause();
                this.extendFlow(10000);
            }
        }

        const frame: SimpleChange = changes.frame;
        if (frame && !frame.firstChange) {
            if (!frame.previousValue || frame.currentValue[1] !== frame.previousValue[1]) {
                this.upgradeStats(frame.currentValue);
            }
        }
        // console.log(frame);
    }
    initFactory(flowtime) {
        return this.builder.build([
            style({'transform': 'translateY(150px)'}),
            animate(flowtime, style({
                'transform': 'translateY(5px)',
                'animation-iteration-count' : 1,
                'animation-timing-function': 'cubic-bezier(.2, .6, .8, .4)',
                'animation-fill-mode': 'forwards',
                'animation-delay': '0.25s'}))
        ]);
    }
    private animate() {
        this.player = this.factory.create(this.fill.nativeElement, {});

        this.player.onDone(() => {
            this.player.pause();
        });
    }
    upgradeStats(frame) {
        if (frame[0] === -1) {
            // TODO for ping
            if (!this.interval) {
                this.stats.curPortion += (this.data.port.lastVal / this.data.port.lastDur) * 0.8;
                this.interval = setInterval(() => {
                    this.stats.curPortion += (this.data.port.lastVal / this.data.port.lastDur) * 0.8;
                }, 1000);
            }} else {
            clearInterval(this.interval);
            this.interval = null;
            this.stats.realPortion += (frame[2] * this.data.port.Q);
            this.stats.daily += (frame[2] * this.data.port.Q);
            this.stats.total += (frame[2] * this.data.port.Q);
            this.stats.curPortion = this.stats.realPortion;
            this.observer.next(true);
            // console.log(this.stats.daily, this.stats.total);
        }
    }
    toggleState () {
        if (!this.loginService.canUpdate()) {
            return;
        }
        if (this.data.port.working === 0 || this.data.port.working === 2) {
            this.player.play();
            this.data.port.working = 1;
            clearTimeout(this.flowTimeout);
        } else {
            this.player.pause();
            this.extendFlow(10000);
            this.data.port.working = 2;
        }
    }
    animationReset() {
        console.log('reset');
        this.player.reset();
    }
    getDevice() {

        this.riotService.getDevice(this.deviceId).then(json => {
            const dev = json.result[0]; // should be always 1
            this.data.dev = dev;
            const k =  dev.ports.find(item => {
                return item.PARAM_NUM.toString() === this.portId.toString();
            });
            if (k) {
                this.data.port = k;
                this.data.port.working = 0;
            }
            this.refreshData();
        });
    }
    changeBarrel() {
        if (!this.loginService.canUpdate()) {
            return;
        }
        this.riotService.changeBarrel(this.deviceId).then(() =>{
            this.getDevice();
        });
    }
    closeFlow() {
        this.data.port.working = 0;
        this.stats.curPortion = 0;
        this.stats.realPortion = 0;
        this.getDevice();
        clearInterval(this.interval);
        this.animationReset();

    }
    extendFlow(s) {
        clearTimeout(this.flowTimeout);
        // if (this.stats.curPortion <= this.data.port.maxPortion)
        this.flowTimeout = setTimeout(() => {
            this.closeFlow();
        }, s);
    }
}
