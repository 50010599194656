import { Component, OnInit, ApplicationRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SimpleGlobal } from 'ng2-simple-global';
import { TranslateService } from '@ngx-translate/core';
import { RiotService } from '../services/riot.service';
import { Config } from '../config/config';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { LoginService} from '../services/login.service';
import { Dictionary } from '../data/dictionary.data';
import { DictionaryService } from '../services/dictionary.service';
import { CookieService } from 'ngx-cookie-service';
import { FunctionService} from '../services/shared.service';


@Component({
    selector: 'app-riot-stats',
    templateUrl: './templates/reports-component.html',
    styleUrls: ['./styles/reports-component.scss']
})

export class RiotReportsComponent implements OnInit {
    @ViewChild('drawer') drawer: any;
    private subscription: Subscription;
    public message: string;
    constructor(
        private route: ActivatedRoute,
        private riotService: RiotService,
        private sg: SimpleGlobal,
        private translate: TranslateService,
        private applicationRef: ApplicationRef,
        public loginService: LoginService,
        private router: Router,
        private dictionaryService: DictionaryService,
        private cookieService: CookieService,
        private functionService: FunctionService
        // private mqttConnectionState: MqttConnectionState
    ) {
    }
    moment = moment;
    config = Config;
    devices = []; fullDevices = [];
    location =  this.sg['global'].filter['location'] || [];
    locations: Dictionary[];
    detail = false;
    rows = [];
    selected = [];
    dayStart = moment().add(-1, 'days').format('YYYY-MM-DD');
    dayEnd = moment().format('YYYY-MM-DD');
    colorScheme = {
        domain: ['#df7e2b', '#eeb274']
    };
    fullColorScheme = {
        domain: [ '#ffb287', '#ffe3d4', '#d56217', '#df7e2b', '#e69138', '#eeb274', '#f8dec3']
    };
    heatColorScheme = {
        domain: [ '#ffffff', '#faec44',  '#ff0000']
    };
    chartData = [];
    chartDailyData = [];

    ngOnInit(): void {
        this.getDict();
        if (!this.loginService.userId) {
            this.router.navigate(['/login']);
            return;
        }
        this.getDeviceData().then(() => {
            if (this.location.length > 0 ) {
                this.filter();
            } else {
                this.getData();
            }
        });
    }
    getDict(): void {
        this.dictionaryService.getList('location').then(locations => this.locations = locations );
    }
    jsonToMultiple(json) {
        const char = [];
        for (const row of json.result) {
            const dev = this.getDeviceInfo(row.device);
            const k =  char.find(item => {
                return item.name === row.device;
            });

            if (k) {
                const l =  k.series.find(item => {
                    return item.name === row.hour;
                });
                k.sum += parseFloat(row.val);
                if (l) {
                    l.series.push({name: row.time, value: row.val, imps: row.imps, duration: row.duration, description: row.description });
                }
            } else {
                const series = [];
                let sum = 0;
                for (let i = 0; i < 24 ; i++) {
                    const val = (i === parseInt(row.hour, 10)) ? row.val  : 0;
                    const hour = i < 10 ? '0' + i : '' + i;
                    const time = val === 0 ? '' : row.time;
                    const imps = val === 0 ? '' : row.imps;
                    const duration = val === 0 ? '' : row.duration;
                    const description = val === 0 ? '' : row.description;
                    series.push({
                        name: hour,
                        series: [{name: time, value: val, imps: imps, duration: duration, description: description }]
                    });
                    sum += parseFloat(val);
                }
                if (dev) { // sometimes it ticks, TODO - fix with embedded devices with flow
                char.push({'name': row.device, 'series': series, 'device' : dev, sum: sum, working: false});
                }
            }
        }
        return char;
    }
    getData(device = null) {
        this.riotService.getRangeRawData(this.location, this.dayStart, this.dayEnd).then(json => {
            this.rows = [...json.result];
            this.chartData = this.functionService.rawDataToChar([...json.result]);
        });
        this.riotService.getRangeDailyData(this.location, this.dayStart, this.dayEnd).then(json => {
            this.chartDailyData = this.functionService.rawDataToChar([...json.result]);
        });
    }
    getDeviceData() {
        return new Promise(resolve => {
        this.riotService.getDevices().then(json => {
            this.devices = this.fullDevices = json.result;
            for (const row of json.result ) {
                if (moment().diff(moment(row.LAST_SEEN), 'minutes') > 10) {
                    row.state = 'dead';
                } else {
                    row.state = 'inactive';
                }
            }
           resolve(json.result);
        });
        });
    }
    getDeviceInfo(dev) {
        const l =  this.devices.find(item => {
            return item.ID  === dev.split('-')[0];
        });
        if (l) {

            const k =  l.ports.find(item => {
                return item.PARAM_NUM  === parseInt(dev.split('-')[1], 10);
            });
            if (k) {
                return {dev: l, port: k };
            }
        }
    }
    filter() {
        if (this.location.length > 0 ) {
            this.getDeviceData().then(() => {
            });
        }
        const devTemp = [];
        this.devices = this.fullDevices;
        for (const row of this.devices ) {
            const k =  this.location.find(item => {
                return (row.LOCATION || '').toString() === item.toString();
            });
            if (k) {
                devTemp.push(row);
            }
        }
        this.devices = [...devTemp];
        this.sg['global'].filter['location'] = this.location;
        this.cookieService.set(this.config.global.appName + '_filter', JSON.stringify(this.sg['global'].filter));
        this.getData();
    }
}
