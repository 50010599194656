import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

import { Dictionary } from '../data/dictionary.data';


@Injectable() export class DictionaryService {
    constructor(private http: HttpClient) { }
    private config = Config;
    getDict(dictionary): Promise<Dictionary[]> {
        const url = `${this.config.api.url}/dictionary/${dictionary.table}/${dictionary.column}`;
        return this.http.get(url)
            .toPromise()
            .then(response => response as Dictionary[])
            .catch(this.handleError);
    }
    getList(list): Promise<Dictionary[]> {
        const url = `${this.config.api.url}/list/${list}`;
        return this.http.get(url)
            .toPromise()
            .then(response => response as Dictionary[])
            .catch(this.handleError);
    }
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

}
