import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';

@Injectable() export class LangService {
    'translations': {
    useValue: {
        main: {
            text: 'I am: %s you are: %s'
        },
        other: {
            withoutInterpolation: 'Star Wars'
        }
    }
}
}
