import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './components/login';
import {RiotStatsComponent} from './components/stats-component';
import {RiotDevicesComponent} from './components/devices-component';
import {RiotLocationComponent} from './components/location-component';
import {RiotReportsComponent} from './components/reports-component';
import {RiotClientComponent} from './components/client-component';

const routes: Routes = [
    {path: '', redirectTo: '/login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'stats', component: RiotStatsComponent},
    {path: 'devices', component: RiotDevicesComponent},
    {path: 'location', component: RiotLocationComponent},
    {path: 'reports', component: RiotReportsComponent},
    {path: 'client', component: RiotClientComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
