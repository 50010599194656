import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
@Injectable()
export class SharedService {
    // Observable string sources
    private emitChangeSource = new Subject<any>();
    // Observable string streams
    changeEmitted$ = this.emitChangeSource.asObservable();
    // Service message commands
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }
}

@Injectable()
export class FunctionService {
    constructor(public sanitizer: DomSanitizer) {
    }
    public devToChar(devs) {
        const charData = {sumA: [], avgD: [], avgA: []};
        for (const dev of devs) {
            for (const port of dev.ports) {
                if (port.state === 1) {
                    charData.sumA.push({name: port.MARK, value: port.sumValA || 0});
                    charData.avgD.push({name: port.MARK, value: port.avgDVal || 0});
                    charData.avgA.push({name: port.MARK, value: port.avgValA || 0});
                }
            }
        }
        return charData;
    }
    public rawDataToChar(rows) {
        const char = [];
        for (const row of rows) {
            const k =  char.find(item => {
                return item.name === row.mark;
            });
            const val = (!row.val || row.val < 0 || isNaN(row.val)) ? 0 : parseFloat(row.val);
            const date = new Date(row.dateStart);
            // const date2 = new Date(row.dateEnd);
            // const date3 = new Date((date2.getTime() + 10000));
            if (val === 0) {
                continue;
            }

            if (k) {
                k.series.push({name: date, value: +val});
            } else {
                char.push({'name': row.mark, 'series': [{name: date, value: +val}]});
            }
        }
        return char;
    }
    public getBackground(image) {
        return this.sanitizer.bypassSecurityTrustStyle(`url(${image})`);
        // return this.sanitizer.bypassSecurityTrustStyle(image);
    }
}
