// angular
import {NgModule, LOCALE_ID, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'hammerjs';
// services
import {JsonService} from './services/json.service';
import {DictionaryService} from './services/dictionary.service';
import {CookieService} from 'ngx-cookie-service';
import {LoginService} from './services/login.service';
import {LangService} from './services/lang.service';
import {RiotService} from './services/riot.service';

import {SharedService, FunctionService} from './services/shared.service';

// modules
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MqttModule, IMqttServiceOptions, IMqttMessage} from 'ngx-mqtt';
import {AppRoutingModule} from './app-routing.module';
import {DpDatePickerModule} from 'ng2-date-picker';
import {Ng2OdometerModule} from 'ng2-odometer';
import { CsvModule } from '@ctrl/ngx-csv';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
// import { CalendarModule } from 'angular-material2-calendar';
import {MaterialModule} from './libs/material.module';
import {SimpleGlobal} from 'ng2-simple-global';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FileUploadModule} from 'ng2-file-upload';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {AngularFontAwesomeModule} from 'angular-font-awesome';

// translation
import {TranslateModule, TranslateParser, TranslateLoader} from '@ngx-translate/core';
import {TranslateICUParser} from 'ngx-translate-parser-plural-select';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// components
import {AppComponent} from './components/app.component';
import {LoginComponent} from './components/login';
import {RiotStatsComponent} from './components/stats-component';
import {RiotDevicesComponent} from './components/devices-component';
import {RiotLocationComponent} from './components/location-component';
import {RiotFlowWidgetComponent} from './components/widgets/flow-widget-component';
import {RiotReportsComponent} from './components/reports-component';
import {RiotClientComponent} from './components/client-component';
import {RiotBeerCardComponent} from "./components/widgets/beer-card-component";
import {RiotBeerFillAnimationComponent} from "./components/widgets/beer-fill-animation-component";
// pipes
import {FlowFormat} from './pipes/format-pipe';
import {RiotEventModalComponent} from './components/modals/event-modal-component';
import {ImageCropperModule} from 'ngx-img-cropper/index';
import {RiotCropperModalComponent} from './components/modals/cropper-modal-component';


//mdb
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';


import { SwiperModule } from 'ngx-swiper-wrapper';
import {NgIdleKeepaliveModule} from "@ng-idle/keepalive";
import {SafeHtml} from "./pipes/safe-html.pipe";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const config = require('./config/config').Config;
export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    connectOnCreate: true,
    hostname: 'vps1.imigne.pl',
    port: 3001,
    clientId: 'riot-ui_' + Math.random().toString(16).substr(2, 8),
    protocol: 'ws',
    // hostWs : 'localhost'
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        CsvModule,
        NgxDatatableModule,
        MaterialModule,
        DpDatePickerModule,
        FileUploadModule,
        // CalendarModule.forRoot(),
        LeafletModule.forRoot(),
        FlexLayoutModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            parser: {
                provide: TranslateParser,
                useClass: TranslateICUParser
            }
        }),
        Ng2OdometerModule.forRoot(),
        NgxChartsModule,
        AngularFontAwesomeModule,
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
        MDBBootstrapModulesPro.forRoot(),
        ImageCropperModule,
        SwiperModule,
        NgIdleKeepaliveModule.forRoot()
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        RiotBeerCardComponent,
        RiotBeerFillAnimationComponent,
        RiotStatsComponent,
        RiotDevicesComponent,
        RiotLocationComponent,
        RiotFlowWidgetComponent,
        RiotEventModalComponent,
        RiotReportsComponent,
        RiotCropperModalComponent,
        RiotClientComponent,
        FlowFormat,
        SafeHtml

    ],
    providers: [
        SharedService, FunctionService,
        SimpleGlobal,
        DictionaryService,
        CookieService,
        LoginService,
        LangService,
        JsonService,
        RiotService,
        MDBSpinningPreloader],
    bootstrap: [AppComponent],
    entryComponents: [RiotFlowWidgetComponent, RiotEventModalComponent, RiotCropperModalComponent],
    schemas: [ NO_ERRORS_SCHEMA ],
    exports: [ImageCropperModule]
})
export class AppModule {
}
