import { Component, OnInit, ApplicationRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SimpleGlobal } from 'ng2-simple-global';
import { TranslateService } from '@ngx-translate/core';
import { RiotService } from '../services/riot.service';
import { Config } from '../config/config';
import * as moment from 'moment';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { Observable, Subscription } from 'rxjs';
import { LoginService} from '../services/login.service';
import { Dictionary } from '../data/dictionary.data';
import { DictionaryService } from '../services/dictionary.service';
import { CookieService } from 'ngx-cookie-service';
import { FunctionService} from '../services/shared.service';

@Component({
    selector: 'app-riot-stats',
    templateUrl: './templates/stats-component.html',
    styleUrls: ['./styles/stats-component.scss']
})

export class RiotStatsComponent implements OnInit {
    @ViewChild('drawer') drawer: any;
    private subscription: Subscription;
    public message: string;
    constructor(
        private route: ActivatedRoute,
        private riotService: RiotService,
        private sg: SimpleGlobal,
        private translate: TranslateService,
        private applicationRef: ApplicationRef,
        private mqttService: MqttService,
        public loginService: LoginService,
        private router: Router,
        private dictionaryService: DictionaryService,
        private cookieService: CookieService,
        private functionService: FunctionService
        // private mqttConnectionState: MqttConnectionState
    ) {
    }

    config = Config;
    selE = {}; selDevice = '';
    devices = []; fullDevices = []; fullCharData = []; charData = []; smallCharData = [];
    location =  this.sg['global'].filter['location'] || [];
    locations: Dictionary[];
    detail = false;
    day = moment().format('YYYY-MM-DD');
    colorScheme = {
        domain: ['#df7e2b', '#eeb274']
    };
    fullColorScheme = {
        domain: [ '#ffb287', '#ffe3d4', '#d56217', '#df7e2b', '#e69138', '#eeb274', '#f8dec3']
    };
    pieCharts = {sumA: [{name: 'dummy', series: []}], avgD: [{name: 'dummy', series: []}], avgA: [{name: 'dummy', series: []}]};
    ngOnInit(): void {
        this.getDict();
        if (!this.loginService.userId) {
            this.router.navigate(['/login']);
            return;
        }
        this.getDeviceData().then(() => {
            if (this.location.length > 0 ) {
                this.filter();
            } else {
                this.pieCharts = this.functionService.devToChar([...this.devices]);
            }
            this.getDayData();
        });
        this.connectMqtt();
    }
    getDict(): void {
        this.dictionaryService.getList('location').then(locations => this.locations = locations );
    }
    connectMqtt () {
        // try {
        //     this.mqttService.connect({});
        // } catch (e) {
        //     console.log(e);
        // }
        this.subscription = this.mqttService.observe('beer/data').subscribe((message: IMqttMessage) => {
            const msg = JSON.parse(message.payload.toString());

            for (const row of msg.data) {

                const k =  this.charData.find(item => {
                    return item.name === row[4] + '-' + row[0];
                });
                if (k) {
                    this.getDayData(row[4] + '-' + row[0]);
                    k.working = false;

                } else {
                    this.getDayData();
                }
            }


        });

        this.subscription = this.mqttService.observe('beer/event').subscribe((message: IMqttMessage) => {
            const msg = JSON.parse(message.payload.toString());
            if (msg.event !== 'flow') {
                return;
            }
                const k =  this.charData.find(item => {
                    return item.name === msg.event_message.device + '-' + msg.event_message.port;
                });
                if (k) {
                    k.working = true;
                    // TODO znaleźć zabezpieczenie, żeby się nie kręciło w nieskończoność
                    // setTimeout(() => {
                    //         k.working = false;
                    //     },
                    //     (7 * 1000));

                }
        });
    }
    // TODO wywalić to do funkcji gdzieś

    jsonToSingle(json) {
        const char = [];
        for (const row of json.result) {
            const k =  char.find(item => {
                return item.name === row.device;
            });

            if (k) {
                k.series[parseInt(row.hour, 10)].value += row.val;
            } else {
                const series = [];
                for (let i = 0; i < 24 ; i++) {
                    const val = (i === parseInt(row.hour, 10)) ? row.val : 0;
                    const hour = i < 10 ? '0' + i : '' + i;
                    series.push({name: hour, value: val});
                }
                char.push({'name': row.device, 'series': series});
            }
        }
        return char;
    }
    jsonToMultiple(json) {
        const char = [];
        for (const row of json.result) {
            const dev = this.getDeviceInfo(row.device);
            const k =  char.find(item => {
                return item.name === row.device;
            });

            if (k) {
                const l =  k.series.find(item => {
                    return item.name === row.hour;
                });
                k.sum += parseFloat(row.val);
                if (l) {
                    l.series.push({name: row.time, value: row.val, imps: row.imps, duration: row.duration, description: row.description });
                }
            } else {
                const series = [];
                let sum = 0;
                for (let i = 0; i < 24 ; i++) {
                    const val = (i === parseInt(row.hour, 10)) ? row.val  : 0;
                    const hour = i < 10 ? '0' + i : '' + i;
                    const time = val === 0 ? '' : row.time;
                    const imps = val === 0 ? '' : row.imps;
                    const duration = val === 0 ? '' : row.duration;
                    const description = val === 0 ? '' : row.description;
                    series.push({
                        name: hour,
                        series: [{name: time, value: val, imps: imps, duration: duration, description: description }]
                    });
                    sum += parseFloat(val);
                }
                if (dev) { // sometimes it ticks, TODO - fix with embedded devices with flow
                char.push({'name': row.device, 'series': series, 'device' : dev, sum: sum, working: false});
                }
            }
        }
        return char;
    }
    getDayData(device = null) {
        return this.riotService.dailyFullFlow(this.day, device).then(json => {
            if (device) {
                const l =  this.charData.find(item => {
                    return item.name === device;
                });
                if (l) {
                    const series = this.jsonToMultiple(json);
                    for (const row of series) {
                        l.series = [...row.series];
                        l.sum = row.sum;
                    }
                }
            } else {
                this.fullCharData = [];
                this.smallCharData = [];
                if (json.result) {
                    this.fullCharData = this.jsonToMultiple(json);
                }
                this.charData = [...this.fullCharData];
            }
        });
    }
    changeDate(e) {
        const day = moment(e).format('YYYY-MM-DD');
        if (this.day === day) {
            return;
        }
        this.day = day;
        this.getDayData();
    }
    changeDay(n) {
        this.day = moment(this.day).add(n, 'days').format('YYYY-MM-DD');
        this.getDayData();
    }
    loadSmallChar(data, device) {
        const k =  this.charData.find(item => {
            return item.name === device;
        });

        if (k) {
            this.selDevice = device;
            const l =  k.series.find(item => {
                return item.name === data.series;
            });
            if (l) {
                this.smallCharData = [...[l]];
            }
        }
    }
    getDeviceData() {
        return new Promise(resolve => {
        this.riotService.getDevices().then(json => {
            this.devices = this.fullDevices = json.result;
            for (const row of json.result ) {
                if (moment().diff(moment(row.LAST_SEEN), 'minutes') > 10) {
                    row.state = 'dead';
                } else {
                    row.state = 'inactive';
                }
            }
           resolve(json.result);
        });
        });
    }
    getDeviceInfo(dev) {
        const l =  this.devices.find(item => {
            return item.ID  === dev.split('-')[0];
        });
        if (l) {

            const k =  l.ports.find(item => {
                return item.PARAM_NUM  === parseInt(dev.split('-')[1], 10);
            });
            if (k) {
                return {dev: l, port: k };
            }
        }
    }
    updateFromDate(e) {
        if (this.loginService.canUpdate()) {
            this.selE = {...e};
            // let's refresh char
            this.smallCharData = [...this.smallCharData];
        } else {
            this.selE = {};
        }
    }
    quantify() {
        if (!this.loginService.canUpdate()) {
            return;
        }
        // console.log(this.day + ' ' + this.selE['name'] );
        if (!this.selE['value'] || !this.selE['imps']) {
            return;
        }
        const from = this.day + ' ' + this.selE['name'];
        const q = this.selE['value'] / this.selE['imps'];
        this.riotService.updateQ(this.selDevice, q, from ).then(json => {
            this.selE = {};
            this.drawer.close();
            this.getDayData(this.selDevice);

        });
    }
    updateFlowDescription() {
        if (!this.loginService.canUpdate()) {
            return;
        }
        const date = this.day + ' ' + this.selE['name'];
        this.riotService.updateFlow(this.selDevice, {description: this.selE['description']} , date ).then(json => {
            this.selE = {};
            this.drawer.close();
            this.getDayData(this.selDevice);

        });
    }
    formatFlow(value) {
        return Number(parseFloat(value)).toFixed(3);
    }
    axisFormat(val) {
        return  Math.round(parseInt(val, 10));
    }
    filter() {
        const devTemp = [];
        this.devices = this.fullDevices;
        for (const row of this.devices ) {
            const k =  this.location.find(item => {
                return (row.LOCATION || '').toString() === item.toString();
            });
            if (k) {
                devTemp.push(row);
            }
        }
        this.devices = [...devTemp];
        this.pieCharts = this.functionService.devToChar([...devTemp]);
        this.sg['global'].filter['location'] = this.location;
        this.cookieService.set(this.config.global.appName + '_filter', JSON.stringify(this.sg['global'].filter));
        this.getDayData();
    }
    clearFilter() {
        if (!this.loginService.restrictedTo) {
            this.location =  this.sg['global'].filter['location'] = [];
            this.cookieService.delete(this.config.global.appName + '_filter');
        }
        this.getDeviceData().then(() => {
            this.getDayData();
            this.pieCharts = this.functionService.devToChar([...this.devices]);
        });
    }
}
