import { Injectable, OnInit } from '@angular/core';
import { Config } from '../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

import { CookieService } from 'ngx-cookie-service';

@Injectable() export class LoginService {
    private config = Config;
    private headers = new HttpHeaders().set('Content-Type', 'application/json');
    userId: any;
    userRole: any;
    userEmail: any;
    userName: any;
    restrictedTo: any;
    constructor(private http: HttpClient, private cookieService: CookieService) {
        if (this.cookieService.get(this.config.global.appName)) {
            const cookie = JSON.parse(this.cookieService.get(this.config.global.appName));
            this.userId = cookie.id;
            this.userEmail = cookie.email;
            this.userRole = cookie.role;
            this.restrictedTo = cookie.restrictedTo;
        }
    }
    login(email, password): Promise<void> {
        const url = `${this.config.api.url}/login`;
        return this.http
            .post(url, JSON.stringify({email: email, password: password}), {headers: this.headers})
            .toPromise()
            .then(response => {
                // let res = response.json();
                if (Object.keys(response).length > 0) {
                    response['email'] = email;
                    this.cookieService.set(this.config.global.appName, JSON.stringify(response));
                    this.userId = response['id'];
                    this.userEmail = response['email'];
                    this.userRole = response['role'];
                    this.restrictedTo = response['restrictedTo'];
                    // this.userName = res[0].name;
                }
                // console.log(response.json();
                })
            .catch(this.handleError);
    }
    reset(email): Promise<void> {
        const url = `${this.config.api.url}/resetpassword`;
        return this.http
            .post(url, JSON.stringify({email: email}), {headers: this.headers})
            .toPromise()
            .then(response => response
                // console.log(response.json();
            )
            .catch(this.handleError);
    }

    logout() {
        this.cookieService.delete(this.config.global.appName);
        this.userId = false;
        this.userEmail = false;
        this.userRole = false;
        this.restrictedTo = false;
    }
    canUpdate() {
        return this.userRole === 9;
    }
    canGenerateCSV() {
        return this.userRole === 9;
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
